<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card">
        <div class="card-body">
            <h5>
                ارسال التأخر التلقائي
            </h5>
            <hr>
          <div class="form-check">
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" value="1" v-model="send_late">
              <h5>
                ارسال التاخر تلقائياً للمعلم
              </h5>
                </label>
            </div><br>
            <div class="form-group">
                <h5 for="">رسالة التاخر</h5>
                <textarea class="form-control" name="" id="" rows="3" v-model="late_message"></textarea>
            </div>
            <div class="form-group">
              <label for="">ارسال الرسائل التلقائية عبر</label>
              <select class="form-control" v-model="send_teachers_late_method">
                <option value="sms">الرسائل النصية SMS</option>
                <option value="whatsapp">الواتس الذكي</option>
              </select>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="save()">
                    حفظ
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            send_late: false,
            late_message: "المعلم {name} تم تسجيل تأخرك الساعة {time}",
            send_teachers_late_method: "sms",
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    created(){
      if(!checkPer("teachers|settings")){
          this.$router.push('/NotPermitted')
          return false;
      }
        var g = this;
        $.post(api + '/user/teachers/settings/send', {
            jwt: g.user.jwt
        }).then(function(r){
            g.send_late = r.response.send_late ?? false;
            g.late_message = r.response.late_message;
            g.send_teachers_late_method = r.response.send_teachers_late_method;
        })
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/user/teachers/settings/send/save', {
                jwt: g.user.jwt,
                late_message: g.late_message,
                send_late: g.send_late,
                send_teachers_late_method: g.send_teachers_late_method
            }).then(function(r){
                alert("تم الحفظ", 100)
            })
        }
    }
}
</script>

<style>

</style>